<template>
  <div class="auth-layout">
    <logo />

    <component :is="page" @navigate="navigate" />

    <div class="grid h-16 grid-cols-1 place-content-end" :class="footerHeight">
      <div class="flex justify-between text-10 sm:text-xs text-invest-dark">
        <p>
          Copyright © {{ year }}, Crednet Technologies • All rights reserved.
        </p>
        <p class="font-semibold">Terms or Use • Policies</p>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, defineAsyncComponent, ref, computed } from 'vue';
export default defineComponent({
  components: {
    login: defineAsyncComponent(() => import('@/components/auth/Login.vue')),
    register: defineAsyncComponent(
      () => import('@/components/auth/Register.vue'),
    ),
    'forgot-password': defineAsyncComponent(
      () => import('@/components/auth/reset/ForgotPassword.vue'),
    ),
  },
  setup: () => {
    const page = ref<string>('login');

    const year = ref<any>(new Date().getFullYear());

    const navigate = (step: string) => (page.value = step);

    const footerHeight = computed(() => {
      let heightClass = 'sm:h-48';
      switch (page.value) {
        case 'forgot-password':
          heightClass = 'sm:h-72';
          break;
        case 'register':
          heightClass = 'sm:h-26';
          break;
        case 'login':
        default:
          heightClass;
      }
      return heightClass;
    });

    return { page, year, navigate, footerHeight };
  },
});
</script>
