
import { defineComponent, defineAsyncComponent, ref, computed } from 'vue';
export default defineComponent({
  components: {
    login: defineAsyncComponent(() => import('@/components/auth/Login.vue')),
    register: defineAsyncComponent(
      () => import('@/components/auth/Register.vue'),
    ),
    'forgot-password': defineAsyncComponent(
      () => import('@/components/auth/reset/ForgotPassword.vue'),
    ),
  },
  setup: () => {
    const page = ref<string>('login');

    const year = ref<any>(new Date().getFullYear());

    const navigate = (step: string) => (page.value = step);

    const footerHeight = computed(() => {
      let heightClass = 'sm:h-48';
      switch (page.value) {
        case 'forgot-password':
          heightClass = 'sm:h-72';
          break;
        case 'register':
          heightClass = 'sm:h-26';
          break;
        case 'login':
        default:
          heightClass;
      }
      return heightClass;
    });

    return { page, year, navigate, footerHeight };
  },
});
